<template>
  <div class="ParkingCouponForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block>
          <el-form-item label="任务" v-if="isEdit">
            <span>{{ form.taskName }}</span>
          </el-form-item>
          <el-form-item label="任务" :rules="[{ required: true, message: '请输入任务', trigger: 'change'}]" prop="taskName" v-else>
            <v-input v-model="form.taskName" placeholder="请输入任务" :width="width" :maxlength="20" />
          </el-form-item>

          <!-- 当任务为预存(二)时，可以添加多个规则 -->
          <div class="prestored-list" v-if="form.taskName === '预存(二)'">
            <el-form-item 
              label="规则"
            >
              <span  class="unit">每次</span>
              <v-button class="botBtn" text="新增规则" @click="addRules"></v-button>
            </el-form-item>
            <div class="prestored-item" v-for="(item,index) in form.prestoredList" :key="index">
              <!-- 预存金额 -->
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="预存金额会自定义多档额度，比如设置500元/1500元/3000元，如果用户预存2000元，按最近的最低档取，即获得1500元的积分">
                <i slot="reference" class="el-icon-question infoSupportType"> </i>
              </el-popover>
              <el-form-item 
                :label="`预存金额${index+1}`"
                :rules="[
                  { required: true, message: '请填写预存金额', trigger: 'blur' },
                  moneyRules
                ]"
                :prop="`prestoredList.${index}.money`"
              >
                <v-input-number 
                  v-model="item.money"
                  :max="99999"
                  :min="1"
                  :width="width1"
                /> 元
                <v-button style="margin-left:10px;" type="danger" class="botBtn" text="删除" @click="delRules(index)"></v-button>
              </el-form-item>
              <!-- 积分 -->
              <el-form-item
                label="积分"
                :prop="`prestoredList.${index}.intNum`"
                :rules="[
                  {
                    required: true,
                    message: '请输入积分',
                    trigger: 'blur',
                  },
                ]"
              >
                <span class="basic-row-text">获赠</span>
                <v-input-number
                  v-model="item.intNum"
                  :max="99999"
                  :min="1"
                  :width="width1"
                />
                <span class="unit">积分</span>
              </el-form-item>
            </div>
          </div>

          <div v-else>
            <!-- 订单消费规则 -->
            <el-form-item
              label="规则"
              :rules="[
                { required: true, message: '请选择规则', trigger: 'blur' },
              ]"
              prop="rulesType"
              v-if="form.rulesType==3"
            >
              <el-radio-group v-model="form.type">
                <div class="periodof">
                  <el-radio label="1">每消费金额</el-radio>
                  <el-form-item
                    prop="value"
                    :rules="[
                      {
                        required: true,
                        message: '请输入消费金额',
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <v-input-number
                      v-model="form.value"
                      :max="99999"
                      :min="0"
                      :width="width1"
                    />
                    <span class="unit">元</span>
                  </el-form-item>
                </div>

                <!-- <div class="periodof">
                  <el-radio label="2">每成功交易</el-radio>
                  <el-form-item
                    prop="termNum"
                    :rules="[
                      {
                        required: true,
                        message: '请输入交易数',
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <v-input-number
                      v-model="form.termNum"
                      :max="99999"
                      :min="0"
                      :width="width1"
                    />
                    <span class="unit">笔</span>
                  </el-form-item>
                </div> -->
                <div class="order-text">
                  {{ form.remarks }}
                </div>
              </el-radio-group>
            </el-form-item>
            <!-- 规则（开户） -->
            <el-form-item
              label="规则"
              :rules="[
                { required: true, message: '请选择规则', trigger: 'blur' },
              ]"
              prop="rulesType"
              v-else-if="form.rulesType==1 && form.taskName === '首次开户'"
            >
              <span class="unit">二类户、三类户完成首次开户</span>
            </el-form-item>
            <el-form-item
              label="规则"
              :rules="[
                { required: true, message: '请选择规则', trigger: 'blur' },
              ]"
              prop="rulesType"
              v-else
            >
              <span v-if="form.taskName === '首次开户'" class="unit">二类户、三类户完成首次开户</span>
              <span v-else-if="form.taskName == '预存(一)'" class="unit">首次完成预存</span>
              <div v-else>
                <v-select
                  v-model="form.rulesType"
                  :options="ruleTypeOptionsOps"
                  :width="width"
                ></v-select>
                <span class="unit">{{ form.remarks }}</span>

              </div>
            </el-form-item>
            <!-- 积分 -->
            <el-form-item
              label="积分"
              prop="intNum"
              :rules="[
                {
                  required: true,
                  message: '请输入积分',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <span class="basic-row-text">获赠</span>
              <v-input-number
                v-model="form.intNum"
                :max="99999"
                :min="1"
                :width="width1"
              />
              <span class="unit">积分</span>
            </el-form-item>
          </div>



          <!-- 《-------------上限 -->
          <el-form-item
            label="上限"
            :rules="[
              { required: true, message: '请选择上限', trigger: 'blur' },
            ]"
            prop="limitedType"
            v-if="form.rulesType == 2 || form.taskName === '预存(二)'"
          >
            <v-select
              v-model="form.limitedType"
              :options="limitTypeOptionsOps"
              :width="width"
            ></v-select>
          </el-form-item>
          <el-form-item label="上限" v-if="form.rulesType == 1">
            <span>/</span>
          </el-form-item>
          <el-form-item
            prop="limitedNum"
            :rules="[
              {
                required: true,
                message: '请输入上限次数',
                trigger: ['blur', 'change'],
              },
            ]"
            v-if="(form.limitedType == 2 || form.limitedType == 3)&&(form.rulesType == 2 || form.taskName === '预存(二)')"
          >
            <span class="basic-row-text">上限</span>
            <v-input-number
              v-model="form.limitedNum"
              :max="99999"
              :min="1"
              :width="width1"
            />
            <span class="unit">次</span>
          </el-form-item>
          <!-- -------------》 -->

          <el-form-item
            label="积分有效期"
            :rules="[
              { required: true, message: '请选择使用有效期', trigger: 'blur' },
            ]"
            prop="effectiveType"
          >
            <el-radio-group v-model="form.effectiveType">
              <div class="periodof">
                <el-radio label="1">永久有效</el-radio>
              </div>
              <!-- <div class="periodof">
                <el-radio label="2">从获得开始至</el-radio>
                <el-form-item
                  :rules="[
                    { required: true, message: '请选择上限', trigger: 'blur' },
                  ]"
                  prop="intimea"
                >
                  <v-select
                    v-model="form.intimea"
                    :options="periodOptionsOps"
                    :width="width"
                  ></v-select>
                </el-form-item>
                <el-form-item
                  prop="fixedDate"
                  :rules="[
                    {
                      required: true,
                      message: '请选择日期',
                      trigger: 'change',
                    },
                  ]"
                >
                  <v-datepicker
                    v-model="form.fixedDate"
                    type="date"
                    :width="width"
                    format="MM-DD"
                  />
                </el-form-item>
              </div>
              <div class="periodof-text">
                若设置为“1年后的01月01日”，则用户在2020年01月01日获得的积分，将在2021年01月01日
                00:00:00失效
              </div>
              <div class="periodof">
                <el-radio label="3">每笔积分有效期为</el-radio>
                <el-form-item
                  prop="termNum"
                  :rules="[
                    {
                      required: true,
                      message: '请输入每笔积分有效期',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <v-input-number
                    v-model="form.termNum"
                    :max="99999"
                    :min="0"
                    :width="width1"
                  />
                </el-form-item>
                <el-form-item
                  :rules="[
                    {
                      required: true,
                      message: '请输入每笔积分有效期',
                      trigger: 'blur',
                    },
                  ]"
                  prop="dateType"
                >
                  <v-select
                    v-model="form.dateType"
                    :options="periodTypeOptionsOps"
                    :width="width"
                  ></v-select>
                </el-form-item>
              </div> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="发放方式"
            prop="disbursement"
            :rules="[
              { required: true, message: '请选择发放方式', trigger: 'change' },
            ]"
          >
            <checkbox-plus
              ref="checkbox"
              class="radio-box"
              type="radio"
              :options="expirationDateOps"
              :value.sync="form.disbursement"
            />
          </el-form-item>
          <el-popover
            v-if="form.disbursement==2"
            placement="top-start"
            width="200"
            trigger="hover"
            content="平均后的小数点部分，会在最后一期统一发放">
            <i slot="reference" class="el-icon-question infoSupportType"> </i>
          </el-popover>
          <el-form-item
            v-if="form.disbursement==2"
            label="发放周期"
            prop="issueCycle"
            :rules="[
              {
                required: true,
                message: '请输入发放周期',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <span class="basic-row-text">分</span>
            <v-input-number
              v-model="form.issueCycle"
              :max="99999"
              :min="1"
              :width="width1"
            />
            <span class="unit">期进行发放</span>
          </el-form-item>
          <div class="stop-package" v-if="form.disbursement==3">根据停车套餐周期进行发放</div>
          <el-form-item
            :rules="[
              { required: true, message: '请选择使用有效期', trigger: 'blur' },
            ]"
            prop="distributingType"
            v-if="form.disbursement==2||form.disbursement==3"
          >
            <el-radio-group v-model="form.distributingType">
              <div class="periodof">
                <el-radio label="1">日平均发放</el-radio>
              </div>
              <div class="periodof">
                <el-radio label="2">周平均发放</el-radio>
                <el-form-item
                  prop="distributingWeek"
                  :rules="[
                    {
                      required: true,
                      message: '请输入每周几发放',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                  v-if="form.distributingType==2"
                >
                <div >
                  <span class="basic-row-text">每周</span>
                  <v-input-number
                    v-model="form.distributingWeek"
                    :max="7"
                    :min="1"
                    :width="width1"
                  />
                  <span class="unit">发放</span>

                </div>
                  
                </el-form-item>
              </div>

              <div class="periodof">
                <el-radio label="3">月平均发放</el-radio>
                <el-form-item
                  prop="distributingMonth"
                  :rules="[
                    {
                      required: true,
                      message: '请输入每月几号发放',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                  v-if="form.distributingType==3"
                >
                <div >
                  <span class="basic-row-text">每月</span>
                  <v-input-number
                    v-model="form.distributingMonth"
                    :max="31"
                    :min="1"
                    :width="width1"
                  />
                  <span class="unit">号发放</span>

                </div>
                  
                </el-form-item>
              </div>
              <div class="unit-text" v-if="form.distributingType==3">
                若某月没有当前日期，则自动移至当月最后一天进行发放
              </div>
            </el-radio-group>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { Radio, RadioGroup } from "element-ui";
import { getDetailURL, updateSysIntegralConfig} from "./api";
import { Col2Detail, Col2Block, CheckboxPlus } from "components/bussiness";
import {
  setRuleTypeOptionsOps,
  setLimitTypeOptionsOps,
  setPeriodOptionsOps,
  setPeriodTypeOptionsOps,
} from "./map";
export default {
  name: "ParkingCouponForm",
  components: {
    Col2Detail,
    Col2Block,
    CheckboxPlus,
    elRadio: Radio,
    elRadioGroup: RadioGroup,
  },
  data() {
    let validatePass = (rule, value, callback)=>{
      let curIndex = rule.fullField.split('.')[1]
      let isError = false 
      this.form.prestoredList.forEach((item,index)=>{
        if (curIndex != index && value == item.money) {
          isError = true
        }
      })
      if (isError) {
        return callback(new Error("该预存金额已存在，请重新填写"))
      } else {
        return callback()
      }
      
    }
    return {
      moneyRules:{validator: validatePass,trigger: 'blur'},
      width1: 80,
      width: 200,
      // ruleTypeOptionsOps: setRuleTypeOptionsOps(2),
      limitTypeOptionsOps: setLimitTypeOptionsOps(2),
      periodOptionsOps: setPeriodOptionsOps(2),
      periodTypeOptionsOps: setPeriodTypeOptionsOps(2),
      form: {        
        sourceType: "", //积分来源类型 1用户注册积分2登录积分3邀请注册4预定xxxx服务5完成服务订单打分评价6申请成为志愿者7成功创建工作室8积分兑换9邻里点赞10评论话题11活动签到12活动报名13访客到达
        // classify: "",
        regionId: "",
        // regionName: "",
        id: '',
        taskName: "",
        rulesType: undefined, //规则类型：1首次2每次3其它
        type: '1',
        value: undefined,
        remarks: "",
        intNum: undefined, // 获赠积分
        limitedType: undefined, //上限
        limitedNum: undefined, //上限次数
        effectiveType: '', //积分有效期类型，1永久2固定日期3每笔有效期
        disbursement: '', //发放方式：1一次性发放2分批发放3具体业务周期发放
        issueCycle: undefined, //发放周期，disbursement为2生效
        distributingType: undefined, //发放类型：1日平均发放2周平均发放3月平均发放
        distributingWeek: undefined, //周平均发放
        distributingMonth: undefined, //月平均发放        
        // termNum: 0,//期限值(月)，effctice_type=3时对应的值
        // dateType: undefined, //日期类型：1年2月3日,effective_type为3生效
        // fixedDate: '', // 固定日期：effective_type为2生效
        prestoredList:[]
      },
      submitConfig: {
        queryUrl: getDetailURL,
        submitUrl: updateSysIntegralConfig
      },
      isEdit: false
    };
  },
  computed: {
    // limitTypeOptionsOps() {
    //   if(this.form.sourceType == 16) {
    //     return setLimitTypeOptionsOps(2).slice(0,2)
    //   } else {
    //     return setLimitTypeOptionsOps(2)
    //   }
    // },
    ruleTypeOptionsOps() {
      if(this.form.sourceType == 16) {
        return setRuleTypeOptionsOps(2).slice(0,1).concat(setRuleTypeOptionsOps(2).slice(2,3))
      } else {
        return setRuleTypeOptionsOps(2)
      }  
    },
    expirationDateOps() {
      if(this.form.sourceType==3){
        return [{
          label: "一次性发放",
          value: "1",
        },
        {
          label: "分批发放",
          value: "2",
        },
        {
          label: "自定义规则",
          value: "3",
        }]
      } if(this.form.sourceType==16) {
        return [{
          label: "一次性发放",
          value: "1",
        }]
      }else {
        return [{
          label: "一次性发放",
          value: "1",
        },
        {
          label: "分批发放",
          value: "2",
        }]
      }     
    }
  },
  mounted() {
    const { id } = this.$route.query;  
    if(id){
      this.isEdit = true
      this.$setBreadcrumb("编辑");
      this.submitConfig.submitMethod = "POST";
      this.$refs.formPanel.getData({
        configId: id,
      });
    } else {
      this.$setBreadcrumb("新增");
    }
      

  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined) {
          this.form[key] = data[key];
        }
      });
      if (data.rulesType === 3) {
        if (data.taskName === "预存(二)") {
          this.form.prestoredList = JSON.parse(data.rulesJson)
        } else {
          this.form.value = JSON.parse(data.rulesJson).value
        }
      }     
      console.log(this.form,'9999')
      this.form.effectiveType = this.form.effectiveType.toString()
      this.form.disbursement = this.form.disbursement.toString()
      this.form.distributingType = this.form.distributingType.toString()
      if(this.form.limitedType === 0){
        this.form.limitedType = undefined
      }
    },
    submitBefore (data) {
      if (data.rulesType === 3) {
        if (data.taskName === "预存(二)") {
          data.rulesJson = JSON.stringify(data.prestoredList)
        } else {
          let rulesJson = {
            type: 1,
            value: data.value,
            remark: ''
          }
          data.rulesJson = JSON.stringify(rulesJson)
        }
      } 
      delete data.prestoredList
      // data.startTime = this.form.time.startTime
      // data.endTime = this.form.time.endTime
      // let expirationDateType = parseInt(this.form.expirationDateType)
      // if (expirationDateType === 1) {
      //   data.expirationDateType = expirationDateType
      // } else {
      //   delete data.expirationHour
      // }
      // delete data.time
      // if (this.form.couponType === 2) {
      //   // 一次全面不需要这两个字段
      //   delete data.price
      //   delete data.couponDuration
      // } else if (this.form.couponType === 0) {
      //   // 金额
      //   delete data.couponDuration
      // }
      return true
    },
    addRules () {
      this.form.prestoredList.push({
        money:undefined,
        intNum:undefined
      })
    },
    delRules (index) {
      this.form.prestoredList.splice(index,1)
    }
  },
};
</script>

<style lang="scss" scoped>
.ParkingCouponForm {
  .unit {
    margin-left: 8px;
    margin-right: 16px;
  }
  .basic-row-text {
    margin-right: 8px;
  }
  .radio-box {
    margin-right: 16px;
  }
  .periodof {
    display: flex;
    padding-top: 20px;
    .el-form-item {
      margin-right: 20px;
    }
  }
  .periodof-text {
    color: #999;
    font-size: 14px;
    padding: 10px 0 0 24px;
  }
  .unit-text{
    color: #999;
    font-size: 14px;
    padding: 20px 0 0 124px;
  }
  .stop-package{
    color: #999;
    font-size: 14px;
    padding-left: 168px;
  }
  .order-text{
    color: #999;
    font-size: 14px;
    padding-top: 20px;
  }
  .infoSupportType {
    left: 150px;
    position: absolute;
  }
}
</style>
