var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ParkingCouponForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _vm.isEdit
                    ? _c("el-form-item", { attrs: { label: "任务" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.taskName))]),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "任务",
                            rules: [
                              {
                                required: true,
                                message: "请输入任务",
                                trigger: "change",
                              },
                            ],
                            prop: "taskName",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入任务",
                              width: _vm.width,
                              maxlength: 20,
                            },
                            model: {
                              value: _vm.form.taskName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "taskName", $$v)
                              },
                              expression: "form.taskName",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm.form.taskName === "预存(二)"
                    ? _c(
                        "div",
                        { staticClass: "prestored-list" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "规则" } },
                            [
                              _c("span", { staticClass: "unit" }, [
                                _vm._v("每次"),
                              ]),
                              _c("v-button", {
                                staticClass: "botBtn",
                                attrs: { text: "新增规则" },
                                on: { click: _vm.addRules },
                              }),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.form.prestoredList,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "prestored-item" },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        width: "200",
                                        trigger: "hover",
                                        content:
                                          "预存金额会自定义多档额度，比如设置500元/1500元/3000元，如果用户预存2000元，按最近的最低档取，即获得1500元的积分",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-question infoSupportType",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: `预存金额${index + 1}`,
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写预存金额",
                                            trigger: "blur",
                                          },
                                          _vm.moneyRules,
                                        ],
                                        prop: `prestoredList.${index}.money`,
                                      },
                                    },
                                    [
                                      _c("v-input-number", {
                                        attrs: {
                                          max: 99999,
                                          min: 1,
                                          width: _vm.width1,
                                        },
                                        model: {
                                          value: item.money,
                                          callback: function ($$v) {
                                            _vm.$set(item, "money", $$v)
                                          },
                                          expression: "item.money",
                                        },
                                      }),
                                      _vm._v(" 元\n              "),
                                      _c("v-button", {
                                        staticClass: "botBtn",
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: { type: "danger", text: "删除" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delRules(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "积分",
                                        prop: `prestoredList.${index}.intNum`,
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入积分",
                                            trigger: "blur",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "basic-row-text" },
                                        [_vm._v("获赠")]
                                      ),
                                      _c("v-input-number", {
                                        attrs: {
                                          max: 99999,
                                          min: 1,
                                          width: _vm.width1,
                                        },
                                        model: {
                                          value: item.intNum,
                                          callback: function ($$v) {
                                            _vm.$set(item, "intNum", $$v)
                                          },
                                          expression: "item.intNum",
                                        },
                                      }),
                                      _c("span", { staticClass: "unit" }, [
                                        _vm._v("积分"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _c(
                        "div",
                        [
                          _vm.form.rulesType == 3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "规则",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择规则",
                                        trigger: "blur",
                                      },
                                    ],
                                    prop: "rulesType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "type", $$v)
                                        },
                                        expression: "form.type",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "periodof" },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("每消费金额")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "value",
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "请输入消费金额",
                                                    trigger: ["blur", "change"],
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c("v-input-number", {
                                                attrs: {
                                                  max: 99999,
                                                  min: 0,
                                                  width: _vm.width1,
                                                },
                                                model: {
                                                  value: _vm.form.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.value",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "unit" },
                                                [_vm._v("元")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "order-text" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.form.remarks) +
                                            "\n              "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm.form.rulesType == 1 &&
                              _vm.form.taskName === "首次开户"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "规则",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择规则",
                                        trigger: "blur",
                                      },
                                    ],
                                    prop: "rulesType",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v("二类户、三类户完成首次开户"),
                                  ]),
                                ]
                              )
                            : _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "规则",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择规则",
                                        trigger: "blur",
                                      },
                                    ],
                                    prop: "rulesType",
                                  },
                                },
                                [
                                  _vm.form.taskName === "首次开户"
                                    ? _c("span", { staticClass: "unit" }, [
                                        _vm._v("二类户、三类户完成首次开户"),
                                      ])
                                    : _vm.form.taskName == "预存(一)"
                                    ? _c("span", { staticClass: "unit" }, [
                                        _vm._v("首次完成预存"),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              options: _vm.ruleTypeOptionsOps,
                                              width: _vm.width,
                                            },
                                            model: {
                                              value: _vm.form.rulesType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "rulesType",
                                                  $$v
                                                )
                                              },
                                              expression: "form.rulesType",
                                            },
                                          }),
                                          _c("span", { staticClass: "unit" }, [
                                            _vm._v(_vm._s(_vm.form.remarks)),
                                          ]),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "积分",
                                prop: "intNum",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入积分",
                                    trigger: ["blur", "change"],
                                  },
                                ],
                              },
                            },
                            [
                              _c("span", { staticClass: "basic-row-text" }, [
                                _vm._v("获赠"),
                              ]),
                              _c("v-input-number", {
                                attrs: {
                                  max: 99999,
                                  min: 1,
                                  width: _vm.width1,
                                },
                                model: {
                                  value: _vm.form.intNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "intNum", $$v)
                                  },
                                  expression: "form.intNum",
                                },
                              }),
                              _c("span", { staticClass: "unit" }, [
                                _vm._v("积分"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _vm.form.rulesType == 2 || _vm.form.taskName === "预存(二)"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上限",
                            rules: [
                              {
                                required: true,
                                message: "请选择上限",
                                trigger: "blur",
                              },
                            ],
                            prop: "limitedType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.limitTypeOptionsOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.limitedType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "limitedType", $$v)
                              },
                              expression: "form.limitedType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.rulesType == 1
                    ? _c("el-form-item", { attrs: { label: "上限" } }, [
                        _c("span", [_vm._v("/")]),
                      ])
                    : _vm._e(),
                  (_vm.form.limitedType == 2 || _vm.form.limitedType == 3) &&
                  (_vm.form.rulesType == 2 || _vm.form.taskName === "预存(二)")
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "limitedNum",
                            rules: [
                              {
                                required: true,
                                message: "请输入上限次数",
                                trigger: ["blur", "change"],
                              },
                            ],
                          },
                        },
                        [
                          _c("span", { staticClass: "basic-row-text" }, [
                            _vm._v("上限"),
                          ]),
                          _c("v-input-number", {
                            attrs: { max: 99999, min: 1, width: _vm.width1 },
                            model: {
                              value: _vm.form.limitedNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "limitedNum", $$v)
                              },
                              expression: "form.limitedNum",
                            },
                          }),
                          _c("span", { staticClass: "unit" }, [_vm._v("次")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "积分有效期",
                        rules: [
                          {
                            required: true,
                            message: "请选择使用有效期",
                            trigger: "blur",
                          },
                        ],
                        prop: "effectiveType",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.effectiveType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "effectiveType", $$v)
                            },
                            expression: "form.effectiveType",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "periodof" },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("永久有效"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发放方式",
                        prop: "disbursement",
                        rules: [
                          {
                            required: true,
                            message: "请选择发放方式",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("checkbox-plus", {
                        ref: "checkbox",
                        staticClass: "radio-box",
                        attrs: {
                          type: "radio",
                          options: _vm.expirationDateOps,
                          value: _vm.form.disbursement,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "disbursement", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.disbursement == 2
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top-start",
                            width: "200",
                            trigger: "hover",
                            content: "平均后的小数点部分，会在最后一期统一发放",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-question infoSupportType",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.form.disbursement == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发放周期",
                            prop: "issueCycle",
                            rules: [
                              {
                                required: true,
                                message: "请输入发放周期",
                                trigger: ["blur", "change"],
                              },
                            ],
                          },
                        },
                        [
                          _c("span", { staticClass: "basic-row-text" }, [
                            _vm._v("分"),
                          ]),
                          _c("v-input-number", {
                            attrs: { max: 99999, min: 1, width: _vm.width1 },
                            model: {
                              value: _vm.form.issueCycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "issueCycle", $$v)
                              },
                              expression: "form.issueCycle",
                            },
                          }),
                          _c("span", { staticClass: "unit" }, [
                            _vm._v("期进行发放"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.disbursement == 3
                    ? _c("div", { staticClass: "stop-package" }, [
                        _vm._v("根据停车套餐周期进行发放"),
                      ])
                    : _vm._e(),
                  _vm.form.disbursement == 2 || _vm.form.disbursement == 3
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            rules: [
                              {
                                required: true,
                                message: "请选择使用有效期",
                                trigger: "blur",
                              },
                            ],
                            prop: "distributingType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.distributingType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "distributingType", $$v)
                                },
                                expression: "form.distributingType",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "periodof" },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("日平均发放"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "periodof" },
                                [
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("周平均发放"),
                                  ]),
                                  _vm.form.distributingType == 2
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "distributingWeek",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入每周几发放",
                                                trigger: ["blur", "change"],
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "basic-row-text",
                                                },
                                                [_vm._v("每周")]
                                              ),
                                              _c("v-input-number", {
                                                attrs: {
                                                  max: 7,
                                                  min: 1,
                                                  width: _vm.width1,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.distributingWeek,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "distributingWeek",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.distributingWeek",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "unit" },
                                                [_vm._v("发放")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "periodof" },
                                [
                                  _c("el-radio", { attrs: { label: "3" } }, [
                                    _vm._v("月平均发放"),
                                  ]),
                                  _vm.form.distributingType == 3
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "distributingMonth",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入每月几号发放",
                                                trigger: ["blur", "change"],
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "basic-row-text",
                                                },
                                                [_vm._v("每月")]
                                              ),
                                              _c("v-input-number", {
                                                attrs: {
                                                  max: 31,
                                                  min: 1,
                                                  width: _vm.width1,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.distributingMonth,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "distributingMonth",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.distributingMonth",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "unit" },
                                                [_vm._v("号发放")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.form.distributingType == 3
                                ? _c("div", { staticClass: "unit-text" }, [
                                    _vm._v(
                                      "\n              若某月没有当前日期，则自动移至当月最后一天进行发放\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }