// 用户积分分页列表 积分已发放/消耗记录列表
const getIntegralListByPage = `${API_CONFIG.butlerBaseURL}tenantIntegral/getIntegralGrantListByPage`
// 积分待发放
const getIntegralWaitGrantListByPage = `${API_CONFIG.butlerBaseURL}tenantIntegral/getIntegralWaitGrantListByPage`
// 分页列表
const getListByPage = `${API_CONFIG.butlerBaseURL}tenantIntegral/getSysIntegralInfoV`

// 是否开启
// const updateByRulesOnById = `${API_CONFIG.butlerBaseURL}integration/integrationConfig/updateByRulesOnById`


// 编辑
const updateSysIntegralConfig = `${API_CONFIG.butlerBaseURL}tenantIntegral/updateSysIntegralConfig`
// tree
const getRegionTreeURL = `${API_CONFIG.butlerBaseURL}enterpriseOrgInfo/getEnterOrgTreeList?orgType=2`
// 获取积分基本信息
// const getSysIntegralInfoURL = `${API_CONFIG.butlerBaseURL}tenantIntegral/getSysIntegralInfoV`
// 更新积分基本信息
const updateSysIntegralInfoURL = `${API_CONFIG.butlerBaseURL}tenantIntegral/updateSysIntegralInfo`
// 获取积分配置详情
const getDetailURL = `${API_CONFIG.butlerBaseURL}tenantIntegral/getSysIntegralConfigDetail`
// 获取个人积分列表
const getPersonalIntegralListByPage = `${API_CONFIG.butlerBaseURL}tenantIntegral/getPersonalIntegralListByPage`

// 个人积分明细
const personalIntegraDetails = `${API_CONFIG.butlerBaseURL}tenantIntegral/personalIntegralDetails`
// 终止发放
const stopURL = `${API_CONFIG.butlerBaseURL}tenantIntegral/editEndGrant`
// 个人积分待发放
const personalIntegralWaitGrant = `${API_CONFIG.butlerBaseURL}tenantIntegral/personalIntegralWaitGrant`
// 自定义积分任务
const getCustomList = `${API_CONFIG.butlerBaseURL}integralTasks/getCustomTasks`
// 新增
const addURL = `${API_CONFIG.butlerBaseURL}integralTasks/addCustomTasks`
// 编辑
const updateURL = `${API_CONFIG.butlerBaseURL}integralTasks/updateCustomTasks`
// 详情
const queryURL = `${API_CONFIG.butlerBaseURL}integralTasks/getCustomTasksDetails`
// 删除
const deleteURL = `${API_CONFIG.butlerBaseURL}integralTasks/deleteCustomTasks`
// 生成二维码
const batchQRcode = `${API_CONFIG.butlerBaseURL}integralTasks/batchQRcode`
export {
	getIntegralListByPage,
	getListByPage,
	updateSysIntegralConfig,
	getRegionTreeURL,
	updateSysIntegralInfoURL,
	getDetailURL,
	getPersonalIntegralListByPage,
	personalIntegraDetails,
	getIntegralWaitGrantListByPage,
	stopURL,
	personalIntegralWaitGrant,
	getCustomList,
	addURL,
	updateURL,
	queryURL,
	deleteURL,
	batchQRcode
}