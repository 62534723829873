import { mapHelper } from 'common/utils'
const platformOptions = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '平台',
    value: '平台'
  }
]

const integraOptions = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '平台',
    value: '平台'
  }
]

const integraTimeOptions = [
  {
    text: '永久有效',
    value: 1
  },
  {
    text: '固定有效期',
    value: 2
  }
]

const ruleTypeOptions = [
  {
    text: '首次',
    value: 1
  },
  {
    text: '每次',
    value: 2
  }
]

const limitTypeOptions = [
  {
    text: '无上限',
    value: 1
  },
  {
    text: '每日',
    value: 2
  },
  {
    text: '整体',
    value: 3
  }
]

const consumTypeOptions = [
  {
    text: '元',
    value: 1
  },
  {
    text: '笔',
    value: 2
  }
]

const consumMap = {
  1: '元',
  2: '笔'
}

const limitTypeMap = {
  1: '无上限',
  2: '每日',
  3: '整体'
}

const integraTimeMap = {
  1: '永久有效',
  2: '固定有效期'
}

const ruleTypeMap = {
  1: '首次',
  2: '每次'
}

const periodOptions = [
  {
    text: '本年',
    value: 1
  },
  {
    text: '次年',
    value: 2
  },
  {
    text: '3年后',
    value: 3
  }
]

const periodTypeOptions = [
  {
    text: '年',
    value: 1
  },
  {
    text: '月',
    value: 2
  },
  {
    text: '日',
    value: 3
  }
]

const grantStatus = [
  {
    text: '待发放',
    value: 1
  },
  {
    text: '已发放',
    value: 2
  },
  {
    text: '终止发放',
    value: 3
  },
  {
    text: '关闭',
    value: 4
  }
]

const {
  map: grantStatusMap,
  setOps: setGrantStatusOps
} = mapHelper.setMap(grantStatus)

const {
  map: periodOptionsMap,
  setOps: setPeriodOptionsOps
} = mapHelper.setMap(periodOptions)

const {
  map: periodTypeOptionsMap,
  setOps: setPeriodTypeOptionsOps
} = mapHelper.setMap(periodTypeOptions)


const {
  map: ruleTypeOptionsMap,
  setOps: setRuleTypeOptionsOps
} = mapHelper.setMap(ruleTypeOptions)


const {
  map: limitTypeOptionsMap,
  setOps: setLimitTypeOptionsOps
} = mapHelper.setMap(limitTypeOptions)


export {
  platformOptions,
  integraOptions,
  integraTimeOptions,
  ruleTypeOptions,
  integraTimeMap,
  ruleTypeMap,
  limitTypeOptions,
  limitTypeMap,
  consumTypeOptions,
  consumMap,
  ruleTypeOptionsMap,
  setRuleTypeOptionsOps,
  limitTypeOptionsMap,
  setLimitTypeOptionsOps,
  periodOptionsMap,
  setPeriodOptionsOps,
  periodTypeOptionsMap,
  setPeriodTypeOptionsOps,
  grantStatusMap,
  setGrantStatusOps
}